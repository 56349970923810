<template>
    <div>
        <el-radio-group v-model="current" size="small">
            <el-radio-button label="QY">产废清运</el-radio-button>
            <el-radio-button label="RK">入库回收</el-radio-button>
            <el-radio-button label="CK">出库利用</el-radio-button>
            <el-radio-button label="Handle">处置</el-radio-button>
        </el-radio-group>
        <transition>
            <component :is="current"></component>
        </transition>
    </div>
</template>

<script>
    import CK from "./ck.vue";
    import QY from "./qy.vue";
    import RK from "./rk.vue";
    import Handle from "./handle.vue";
    export default {
        data() {
            return {
                current: "QY",

                unitList: [],
                wasteTypes: [],
                useTypes: [],
                uUnitList: []
            };
        },
        created() {
            this.init();
        },
        methods: {
            async init() {
                this.getWasteTypes();
                this.getUnitList();
                this.getUUnitList();
                this.getUseTypes();
            },
            getWasteTypes() {
                this.$http.post("/wasteType/listAll").then(res => {
                    this.wasteTypes = res.detail;
                });
            },
            getUnitList() {
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "3"
                    })
                    .then(res => {
                        this.unitList = res.detail;
                    });
            },
            getUUnitList() {
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "1"
                    })
                    .then(res => {
                        this.uUnitList = res.detail;
                    });
            },
            getUseTypes() {
                this.$http.post("/dict/getDictValuesByDictId", "dictuseway").then(res => {
                    this.useTypes = res.detail;
                });
            },
        },
        components: {
            CK,
            QY,
            RK,
            Handle
        }
    };
</script>
<style></style>
