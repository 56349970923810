<template>
    <div>
        <jy-query :model="form" ref="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="辖区纬度" prop="regionId">
                <div @click="selectRegion">
                    <el-input placeholder="请选择" v-model="form.regionNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="分拣中心" prop="unitId">
                <el-select v-model="form.unitId">
                    <el-option
                        v-for="u in unitList"
                        :key="u.unitId"
                        :value="u.unitId"
                        :label="u.cname"
                    ></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="利用单位" prop="uUnitId">
                <el-select v-model="form.uUnitId">
                    <el-option
                        v-for="u in uUnitList"
                        :key="u.unitId"
                        :value="u.unitId"
                        :label="u.cname"
                    ></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="利用方式" prop="usage">
                <el-select v-model="form.usage">
                    <el-option
                        v-for="t in useTypes"
                        :key="t.iValue"
                        :label="t.iValue"
                        :value="t.iValue"
                    ></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="出库时间" prop="createT">
                <el-date-picker
                    :clearable="false"
                    type="daterange"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    v-model="form.createT"
                ></el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')">重置</el-button>
                <el-row>
                    <export-excel
                        baseUrl="/iwastes-admin"
                        url="/wasteOrder/export"
                        :params="form"
                        fileName="辖区固废日报-出库利用"
                        fileType=".xls"
                        size="small"
                    ></export-excel>
                </el-row>
            </template>
        </jy-query>
        <div>搜索结果合计：记录{{ total }}条，出库利用量{{ sum }}kg</div>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="tableData">
                <jy-table-column type="index" label="序号" width="55"></jy-table-column>
                <jy-table-column label="省">
                    <template
                        v-slot="scope"
                    >{{ scope.row.province || '-' }}</template>
                </jy-table-column>
                <jy-table-column label="市">
                    <template
                        v-slot="scope"
                    >{{ scope.row.city || '-' }}</template>
                </jy-table-column>
                <jy-table-column label="区县">
                    <template
                        v-slot="scope"
                    >{{ scope.row.county || '-' }}</template>
                </jy-table-column>
                <jy-table-column label="街道/镇/乡">
                    <template
                        v-slot="scope"
                    >{{ scope.row.street || '-' }}</template>
                </jy-table-column>
                <jy-table-column label="出库分拣中心">
                    <template v-slot="scope">{{ getUnitName(scope.row.unitId) }}</template>
                </jy-table-column>
                <jy-table-column label="利用单位">
                    <template v-slot="scope">
                        {{ getUUnitName(scope.row.uUnitId) }}
                    </template>
                </jy-table-column>
                <jy-table-column label="利用方式">
                    <template v-slot="scope">
                        {{ scope.row.usage }}
                    </template>
                </jy-table-column>
                <jy-table-column label="出库物资">
                    <template v-slot="scope">
                        {{ scope.row.materialNas }}
                    </template>
                </jy-table-column>
                <el-table-column label="出库利用" class-name="jy_table_column">
                    <el-table-column class-name="jy_table_column" label="出库时间">
                        <template v-slot="scope">
                            {{ scope.row.time }}
                        </template>
                    </el-table-column>
                    <el-table-column class-name="jy_table_column" label="出库利用量（kg）" width="150">
                        <template v-slot="scope">
                            {{ scope.row.num }}
                        </template>
                    </el-table-column>
                </el-table-column>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <region-tree ref="region" @select="addRegion"></region-tree>
    </div>
</template>

<script>
import ExportExcel from "@/components/pages/admin/common/export";
import RegionTree from "@/components/pages/admin/common/dialog-tree/region_tree.vue";
export default {
    methods: {
        selectRegion() {
            this.$refs.region.init({ pid: this.pid });
        },
        addRegion(data) {
            this.form.regionId = data.regionId;
            this.form.regionNa = data.regionNa;
        },
        oncheck() {
            this.pageIndex = 1;
            this.getList();
        },
        getList() {
            this.loading = true;
            this.$http
                .post("/jurisdiction/waste/stockRecovery", {
                    ...this.form,
                    timeStart: this.form.createT[0],
                    timeEnd: this.form.createT[1],
                    createT: undefined,
                    ioT: "0",
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                })
                .then(res => {
                    console.log(res, 'res')
                    this.tableData = res.detail.list;
                    this.total = res.detail.total;
                    this.unit = this.wasteTypes
                        .find(each => each.wasteTId === this.form.wasteTId)
                        .unit;
                })
                .finally(() => {
                    this.loading =false;
                })
        },
        getStat() {
            this.$http
                .post('/jurisdiction/waste/stockRecoveryTotal', {
                    ioT: '0'
                })
                .then(res => {
                    this.sum = res.detail.sum;
                })
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields();
            Object.keys(this.form).forEach(v => {
                this.form[v] = null;
            });
            this.form.createT = this.formatInitTime(7, 0, true);

            this.oncheck();
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.pageIndex = val;
            this.getList();
        },
        formatInitTime(start, end, hms = false) {
            // start:开始时间前几天，end：结束前几天 hms:是否需要时间
            let startDate = new Date();
            let endDate = new Date();
            let startTime = this.$util.parseTime(new Date(startDate.setDate(startDate.getDate() - start)), "yyyy-MM-dd"),
                endTime = this.$util.parseTime(new Date(endDate.setDate(endDate.getDate() - end)), "yyyy-MM-dd");
            if (hms) {
                startTime = startTime + " " + "00:00:00";
                endTime = endTime + " " + "23:59:59";
            }
            return [startTime, endTime];
        },
        getUnitName(unitId) {
            const unit = this.$parent.unitList.find(v => v.unitId === unitId);
            if (unit) {
                return unit.cname;
            }
            return '-';
        },
        getUUnitName(uUnitId) {
            const uUnit = this.$parent.uUnitList.find(v => v.unitId === uUnitId)
            if (uUnit) {
                return uUnit.cname;
            }
            return '-';
        }
    },
    data() {
        return {
            loading: false,
            tableData: [],
            total: 0,
            sum: 0,
            pid: 0,

            form: {
                unitId: "",
                regionId: null,
                regionNa: "",
                uUnitId: "",
                usage: "",
                createT: []
            },

            pageIndex: 1,
            pageSize: 10
        };
    },
    created() {
        if (this.$store.state.info.regionId) {
            this.pid = this.$store.state.info.regionId;
        }
        this.form.createT = this.formatInitTime(7, 0, true);
        this.getStat();
    },
    computed: {
        useTypes() {
            return this.$parent.useTypes;
        },
        uUnitList() {
            return this.$parent.uUnitList;
        },
        unitList() {
            return this.$parent.unitList;
        }
    },
    components: {
        ExportExcel,
        RegionTree
    }
};
</script>
<style scoped>
.query_area {
    margin-top: 15px;
}
</style>
