<template>
    <div>
        <jy-query ref="form" labelWidth="100" class="query_area" size="small">
            <jy-query-item label="辖区纬度">
                <div @click="selectRegion">
                    <el-input placeholder="请选择" v-model="form.regionNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="出库分拣中心">
                <el-select v-model="form.unitId">
                    <el-option v-for="u in unitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="处置单位">
                <el-select v-model="form.dUnitId">
                    <el-option v-for="u in hUnitList" :key="u.unitId" :value="u.unitId" :label="u.cname"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="处置方式" prop="handleT">
                <el-select v-model="form.delway">
                    <el-option v-for="t in handleTypes" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="固废类别">
                <el-select v-model="form.wasteTId">
                    <el-option v-for="t in wasteTypes" :key="t.wasteTId" :label="t.cName" :value="t.wasteTId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="处置日期">
                <el-date-picker
                    :value="[form.dealTStart, form.dealTEnd]"
                    @input="handleDateInput"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :default-time="['00:00:00', '23:59:59']"
                    :clearable="false"
                >
                </el-date-picker>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck">查询</el-button>
                <el-button size="small" type="primary" @click="reset">重置</el-button>
                <el-row>
                    <export-excel fileName="委托订单" fileType=".xls" url="" size="small"></export-excel>
                </el-row>
            </template>
        </jy-query>
        <div>搜索结果合计：记录{{ total }}条，处置净重{{ sum }}kg</div>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column label="省">
                    <template v-slot="scope">
                        {{ (scope.row.addrRegion.province && scope.row.addrRegion.province.regionNa) || scope.row.addr }}
                    </template>
                </jy-table-column>
                <jy-table-column label="市">
                    <template v-slot="scope">
                        {{ scope.row.addrRegion.city && scope.row.addrRegion.city.regionNa }}
                    </template>
                </jy-table-column>
                <jy-table-column label="区县">
                    <template v-slot="scope">
                        {{ scope.row.addrRegion.district && scope.row.addrRegion.district.regionNa }}
                    </template>
                </jy-table-column>
                <jy-table-column label="街道/镇/乡">
                    <template v-slot="scope">
                        {{ scope.row.addrRegion.street && scope.row.addrRegion.street.regionNa }}
                    </template>
                </jy-table-column>
                <jy-table-column label="出库分拣中心" width="120">
                    <template v-slot="scope">
                        {{ scope.row.unitInfo.cname }}
                    </template>
                </jy-table-column>
                <jy-table-column label="固废类别">
                    {{ getWasteType() }}
                </jy-table-column>
                <jy-table-column label="处置单位">
                    <template v-slot="scope">
                        {{ scope.row.dunitInfo.cname }}
                    </template>
                </jy-table-column>
                <jy-table-column label="处置方式">
                    <template v-slot="scope">
                        {{ scope.row.delway }}
                    </template>
                </jy-table-column>
                <jy-table-column label="处置出场日期" width="120">
                    <template v-slot="scope">
                        {{ scope.row.dealT }}
                    </template>
                </jy-table-column>
                <el-table-column label="处置" class-name="jy_table_column">
                    <el-table-column class-name="jy_table_column" label="毛重过磅时间" width="120">
                        <template v-slot="scope">{{ scope.row.dgrossWT }}</template>
                    </el-table-column>
                    <el-table-column class-name="jy_table_column" :label="`毛重(${ unit })`" width="100">
                        <template v-slot="scope">{{ scope.row.dgrossW }}</template>
                    </el-table-column>
                    <el-table-column class-name="jy_table_column" label="皮重过磅时间" width="120">
                        <template v-slot="scope">{{ scope.row.dtareWT }}</template>
                    </el-table-column>
                    <el-table-column class-name="jy_table_column" :label="`皮重(${ unit })`" width="120">
                        <template v-slot="scope">{{ scope.row.dtareW }}</template>
                    </el-table-column>
                    <el-table-column class-name="jy_table_column" :label="`净重(${ unit })`" width="120">
                        <template v-slot="scope">
                            {{ formatNumber(scope.row.dgrossW, scope.row.dtareW) }}
                        </template>
                    </el-table-column>
                </el-table-column>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>
        <region-tree ref="region" @select="addRegion"></region-tree>
    </div>
</template>

<script>
    import ExportExcel from "@/components/pages/admin/common/export";
    import RegionTree from "@/components/pages/admin/common/dialog-tree/region_tree.vue";

    export default {
        created() {
            if (this.$store.state.info.regionId) {
                this.pid = this.$store.state.info.regionId;
            }
            this.init();
        },
        methods: {
            async init() {
                this.getStat();
                this.initTime();
                this.getUnitList();
                this.getHandleTypeList();
                this.getHUnitList();
                await this.getWasteTypeList();
                this.oncheck();
            },
            initTime() {
                const cur = new Date();
                const prev = new Date(cur);
                prev.setDate(cur.getDate() - 7);
                this.form.dealTStart = prev.Format("yyyy-MM-dd hh:mm:ss");
                this.form.dealTEnd = cur.Format('yyyy-MM-dd hh:mm:ss');
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            reset() {
                Object.keys(this.form).forEach(v => {
                    this.form[v] = null;
                });
                this.init();
            },
            getList() {
                this.loading = true;
                this.$http
                    .post('/jurisdiction/waste/dayDeal', {
                        ...this.form,
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                    })
                    .then(res => {
                        this.dataList = res.detail.list;
                        this.total = res.detail.total;
                        this.unit = this.wasteTypes
                            .find(each => each.wasteTId === this.form.wasteTId)
                            .unit;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },
            getWasteTypeList() {
                return this.$http
                    .post("/wasteType/listAll")
                    .then(res => {
                        this.wasteTypes = res.detail;
                        this.form.wasteTId = this.wasteTypes[0].wasteTId;
                        this.unit = this.wasteTypes[0].unit;
                    });
            },
            getUnitList() {
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "3"
                    })
                    .then(res => {
                        this.unitList = res.detail;
                    });
            },
            getHandleTypeList() {
                this.$http
                    .post("/dict/getDictValuesByDictId", "dictdelway")
                    .then(res => {
                        this.handleTypes = res.detail;
                    });
            },
            getHUnitList() {
                this.$http
                    .post("/unitInfo/queryUnitList", {
                        cName: "",
                        unitType: "2"
                    })
                    .then(res => {
                        this.hUnitList = res.detail;
                    });
            },
            getWasteType() {
                const waste = this.wasteTypes.find(v => v.wasteTId === this.form.wasteTId)
                if (waste) {
                    return waste.cName;
                }
                return '';
            },
            getStat() {
                this.$http
                    .post('/jurisdiction/waste/dayDealTotal')
                    .then(res => {
                        this.sum = res.detail.sum;
                    })
            },
            add() {
                this.$emit("switch");
            },
            addRegion(data) {
                this.form.regionId = data.regionId;
                this.form.regionNa = data.regionNa;
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            selectRegion() {
                this.$refs.region.init({ pid: this.pid });
            },
            formatNumber(v1, v2) {
                if (isNaN(v1) || isNaN(v2)) {
                    return "-";
                }
                return (v1 - v2).toFixed(2);
            },
            handleDateInput(val) {
                [this.form.dealTStart, this.form.dealTEnd] = val
            }
        },
        data() {
            return {
                loading: false,
                dataList: [],
                total: 0,
                sum: 0,
                pid: null,

                pageIndex: 1,
                pageSize: 10,

                unit: '',

                form: {
                    unitId: null,
                    wasteTId: null,
                    regionId: null,
                    regionNa: null,
                    delway: null,
                    dUnitId: null,
                    dealTStart: null,
                    dealTEnd: null,
                },

                unitList: [],
                wasteTypes: [],
                handleTypes: [],
                hUnitList: [],
            };
        },
        watch: {},
        components: {
            ExportExcel,
            RegionTree
        }
    };
</script>
<style scoped>
    .query_area {
        margin-top: 10px;
    }
</style>
